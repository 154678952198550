<template>
  <div v-if="isEdit" class="setting-or">
    <div class="title">
      <img src="@/assets/icons/recognition-yellow.svg" alt="">
      <span>{{ $t('ai_recognition_setting')/*辨識設定*/ }}</span>
    </div>
    <div class="content">
      <div class="tab-content" :class="{ edit: isEdit }">
        <SwitchSetter v-if="aiRunOn === 'device'" v-model="paramSrc" :data="configOr.paramSource" :enabled="!isAiCam" />
        <SwitchSetter v-if="aiRunOn === 'device'" v-model="orChannel.isEnabled" :data="configOr.isEnabled" :enabled="canSetting" />
        <SwitchSetter v-model="orChannel.mode" :data="configOr.orMode" :enabled="canSetting" />
        <span class="sub-title">{{ $t('ai_detect_target_threshold')/*偵測目標閾值*/ }}</span>
        <!-- orChannel.mode = 1 => or / mode = 2 => md -->
        <template v-if="orChannel.mode === 1">
          <SliderSetter
            v-for="(item, index) in ['personLevel', 'carLevel', 'bikeLevel', 'truckLevel', 'busLevel']"
            :key="index"  
            v-model="orChannel.or[item]" 
            :data="configOr[item]" 
            :enabled="canSetting"
          />
        </template>
        <template v-else>
          <SliderSetter
            v-for="(item, index) in ['motionLevel']"
            :key="index"  
            v-model="orChannel.md[item]" 
            :data="configOr[item]" 
            :enabled="canSetting"
          />
        </template>
        <div class="expert-mode" @click="showAdvSettings = !showAdvSettings">
          <img :class="{ 'arrow-right': !showAdvSettings}" src="@/assets/icons/Arrow_down.svg" alt="">
          <img src="@/assets/icons/graduation-cap.svg" alt="">
          <span>{{ $t('ai_advanced_settings')/*專家模式*/ }}</span>
        </div>
        <template v-if="showAdvSettings">
          <SliderSetter v-model="orChannel[orMode].recgFpsLevel" :data="configOr.recgFpsLevel" />
          <SliderSetter v-if="aiRunOn === 'device'" v-model="orDeviceConfig[orMode].eventDuration" :data="configOr.eventDuration" />
          <SliderSetter v-else v-model="orDeviceConfig[orMode].repeatInterval" :data="configOr.repeatInterval" />
          <SliderSetter v-model="orChannel[orMode].staySec" :data="configOr.staySec" />
          <SliderSetter v-if="aiRunOn === 'device'" v-model="orDeviceConfig.repostDataStoreLimit" :data="configOr.repostDataStoreLimit" />
        </template>
      </div>
    </div>
  </div>
  <div v-else class="setting-or">
    <div class="title">
      <img src="@/assets/icons/recognition-yellow.svg" alt="">
      <span>{{ $t('ai_recognition_setting')/*辨識設定*/ }}</span>
    </div>
    <div class="content">
      <div class="tab-content">
        <ConfigItem v-if="aiRunOn === 'device'" v-model="paramSrc" :data="configOr.paramSource" :showTooltip="false" />
        <ConfigItem v-if="aiRunOn === 'device'" v-model="orChannel.isEnabled" :data="configOr.isEnabled" :showTooltip="false" />
        <ConfigItem v-model="orChannel.mode" :data="configOr.orMode" :showTooltip="false" />
        <span class="sub-title">{{ $t('ai_detect_target_threshold')/*偵測目標閾值*/ }}</span>
          <template v-if="orChannel.mode === 1">
            <ConfigItem
              v-for="(item, index) in ['personLevel', 'carLevel', 'bikeLevel', 'truckLevel', 'busLevel']"
              :key="index"  
              v-model="orChannel.or[item]" 
              :data="configOr[item]" 
              :showTooltip="false" 
            />
          </template>
          <template v-else>
            <ConfigItem
              v-for="(item, index) in ['motionLevel']"
              :key="index"  
              v-model="orChannel.md[item]" 
              :data="configOr[item]" 
              :showTooltip="false" 
            />
          </template>
        
        <span class="sub-title">
          <img src="@/assets/icons/graduation-cap.svg" alt="">
          {{ $t('ai_advanced_settings')/*專家模式*/ }}
        </span>
        <ConfigItem v-model="orChannel[orMode].recgFpsLevel" :data="configOr.recgFpsLevel" />
        <ConfigItem v-if="aiRunOn === 'device'" v-model="orDeviceConfig[orMode].eventDuration" :data="configOr.eventDuration" />
        <ConfigItem v-else v-model="orDeviceConfig[orMode].repeatInterval" :data="configOr.repeatInterval" />
        <ConfigItem v-model="orChannel[orMode].staySec" :data="configOr.staySec" />
        <ConfigItem v-if="aiRunOn === 'device'" v-model="orDeviceConfig.repostDataStoreLimit" :data="configOr.repostDataStoreLimit" />
        <div class="line"></div>
        <div class="time">
          <span>{{ $t('ai_updated_time')/*更新時間*/ }}</span>
          <span v-if="aiRunOn === 'device'">{{ formatTime(currentSetting.updateTime) }}</span>
          <span v-else>{{ formatTime(aiBoxTask.updatedTime) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { configOr } from '@/config/configOr'
import { formatTime } from '@/utils/lib.js'

export default {
  name: 'LprSetting',
  components: {
    SwitchSetter: () => import('@/components/AiBox/base/SwitchSetter.vue'),
    SliderSetter: () => import('@/components/AiBox/base/SliderSetter.vue'),
    ConfigItem: () => import('@/components/AiBox/base/ConfigItem.vue'),
  },
  data() {
    return {
      configOr,
      globalConfig: {},
      userConfig: {},
      showParameters: true,
      showAdvSettings: false,
      isEditExpertMode: false,
      isViewExpertMode: false,
    }
  },
  computed: {
    ...mapState('aibox', ['aiRunOn', 'isEdit', 'isEditRoi']),
    ...mapState('recgOr', ['currDefaultSetting', 'defaultSetting', 
      'camIndex', 'userSetting']),
    ...mapGetters('recgOr', ['isAiCam', 'currentSetting', 'userId', 'orChannelIndex']),
    ...mapGetters('aibox', ['aiBoxTask']),
    paramSrc: {
      get() {
        return this.currDefaultSetting
      },
      set(val) {
        this.onUpdateCurrDefaultSetting(val)
      },
    },
    orMode() {
      return this.orChannel.mode === 1 ? 'or' : 'md'
    },  
    canSetting() {
      // 雲端物件辨識只看isEditRoi / 本機物件辨識還需判斷是否為系統預設或設備客製
      // defaultSetting = 0: userSetting 設備客製/可編輯, 1: defaultSetting 系統預設/不可編輯
      if (this.aiRunOn === 'device') {
        return this.currDefaultSetting === 0 && !this.isEditRoi
      } else {
        return !this.isEditRoi
      }
    },
    orDeviceConfig() {
      if (this.aiRunOn === 'device')
        return this.currentSetting.setting.deviceConfig.or
      else 
        return this.aiBoxTask?.config
    },
    orChannel: {
      get() {
        if (this.aiRunOn === 'device')
          return this.currentSetting.setting.channel[this.orChannelIndex].or
        else
          return this.aiBoxTask.config
      },
      set(data) {
        if (this.aiRunOn === 'device') {
          this.updateChannelData({
            channelIndex: this.orChannelIndex,
            key: 'or',
            value: data,
          })
        } else {
          this.updateCurrTaskConfig(data)
        }
      },
    },
  },
  methods: {
    ...mapMutations('recgOr', ['updateSetting', 
      'updateCamIndex', 'updateROI', 'updateLprNotifyFilterMode', 'updateChannelData'
    ]),
    ...mapMutations('aibox', ['updateCurrTaskConfig']),
    ...mapActions('recgOr', ['onUpdateCurrDefaultSetting']),
    formatTime,
    resetRoi() {
      this.updateROI({
        x1: 0,
        y1: 0,
        x2: 1,
        y2: 1,
      })
    },
    switchCam(index) {
      this.showParameters = false
      this.updateCamIndex(index)
    }
  },
}
</script>

<style lang="scss" scoped>
* {
  user-select: none;
}
.setting-or {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .title {
    display: flex;
    align-items: center;
    font-size: px2rem(16);
    line-height: 24px;
    font-weight: 700;
    color: #FFE99F;
    margin-bottom: 8px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 46px);
    box-sizing: border-box;
    position: relative;

    .tab-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      padding: 12px 4px 12px 12px;
      background: #39425D;
      overflow-y: scroll;
      border-radius: 8px;
      &.edit {
        background: #151B35;
      }

      .sub-title {
        color: #FFE99F;
        font-weight: 700;
      }
    }

    .line {
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #ffffff80;
    }
    
    .expert-mode {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      color: #FFE99F;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        @include filter_FFE99F;
        transition: all 0.3s;
        &.arrow-right {
          transform: rotate(-90deg);
        }
      }
    }
    .mode {
      color:#ffffff80;
    }

    .time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: #ffffff80;
        font-size: px2rem(14);
      }
      span:first-child {
        font-size: px2rem(16);
      }
    }
  }
}

.wrap-edit-roi {
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-bottom: 12px;
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
}
.edit-roi {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  padding: 4px 12px;
  background: #4A5C78;
  border-radius: 8px;
  cursor: pointer;
  
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}
.reset-roi {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background: #4A5C7880;
  cursor: pointer;
  &:hover {
    background: #4A5C78;
  }
}
</style>